import React from "react";
import Image from "next/image";
import { whyChooseUs } from "DataMapper/Refi/LandingPageConstants";

function WhySection() {
  return (
    <section className="py-[40px] px-[16px] md:pt-[60px] bg-[#E9E8FA]">
      <h2 className="text-[24px] md:text-[36px] leading-[28px] md:leading-[46px] text-[#1B1D6A] md:text-center font-bold">
        Why choose us?
      </h2>
      <div className="md:py-[24px] md:px-[5%] block md:grid grid-cols-3 grid-row-2 gap-4">
        {whyChooseUs?.map((item) => (
          <div
            key={`${item.title}`}
            className="bg-white px-[24px] py-[16px] md:py-[28px] rounded-lg my-4"
          >
            <Image width={24} height={24} src={item.image} alt={item.alt} />
            <h3 className="text-[#26282B] font-bold text-[22px] leading-7 my-[15px]">
              {item.title}
            </h3>
            <p className="text-[#484A4D] leading-6 text-[16px]">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhySection;
